import React from 'react';
import { Helmet } from 'react-helmet';
import { Grid, GridItem, Box, Container } from '@chakra-ui/react';

import NavBar from '../../components/navbar';
import Footer from '../../components/footer';

import graphic from '../../images/creditcalculator-graphic.jpg';
import favicon from '../../images/favicon.ico';

const Layout = ({ children, title, description }) => (
  <>
    <Helmet>
      {/* General tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="image" content={graphic} />

      {/* OpenGraph tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={graphic} />
      <meta property="og:image:width" content="512" />
      <meta property="og:image:height" content="512" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://creditcalculator-app.com/" />
      <meta property="fb:app_id" content="541351040141286" />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={graphic} />
      <link rel="shortcut icon" href={favicon} type="image/x-icon" />
      <link rel="icon" href={favicon} type="image/x-icon" />
    </Helmet>
    <Grid
      className="layout"
      gridTemplateColumns="1fr"
      gridTemplateRows="7rem 1fr auto"
      templateAreas={`'header' 'content' 'footer'`}
      minHeight="100vh"
    >
      <GridItem as="header" gridArea="header" zIndex="1">
        <Box height="7rem" bgColor="gray.800" position="fixed" width="100vw">
          <Container maxWidth="container.xl" height="100%" paddingX="0">
            <NavBar />
          </Container>
        </Box>
      </GridItem>
      <GridItem as="section" gridArea="content">
        <Box height="100%">{children}</Box>
      </GridItem>
      <GridItem as="footer" gridArea="footer">
        <Box minHeight="10rem" bgColor="gray.800">
          <Container maxWidth="container.xl">
            <Footer />
          </Container>
        </Box>
      </GridItem>
    </Grid>
  </>
);

export default Layout;
