import React from 'react';
import { Link, Flex, Text, Box, Heading } from '@chakra-ui/react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const DescriptionLink = ({ children, href, label }) => (
  <Flex flexDirection="row" alignItems="center">
    <Text fontSize="sm" color="white" marginRight="1.2rem">
      {`${label}:`}
    </Text>
    <Link
      href={href}
      fontSize="sm"
      color="white"
      marginBottom="1.2rem"
      _last={{ marginBottom: 0 }}
    >
      {children}
    </Link>
  </Flex>
);

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Box className="Footer" paddingTop="8rem" paddingBottom="16rem">
      <Flex flexDirection="column">
        <Heading
          as="h4"
          fontSize={{ base: 'sm', lg: 'md' }}
          color="white"
          marginBottom="1.6rem"
          textTransform="capitalize"
        >
          {t('contact-us')}
        </Heading>
        <DescriptionLink
          href="mailto:app.credit.calculator@gmail.com"
          label="Email"
        >
          app.credit.calculator@gmail.com
        </DescriptionLink>
      </Flex>
    </Box>
  );
};

export default Footer;
