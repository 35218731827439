import React, { useState, useRef } from 'react';
import {
  Link,
  Button,
  Flex,
  Text,
  Image,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Divider,
} from '@chakra-ui/react';
import { ChevronDownIcon, CheckIcon } from '@chakra-ui/icons';
import {
  Link as i18NextLink,
  useTranslation,
} from 'gatsby-plugin-react-i18next';

import logoSrc from '../../images/logo.jpg';

const LANGUAGES = ['en', 'es'];

const NavLink = ({ children, to }) => (
  <Link
    as={i18NextLink}
    to={to}
    fontSize={{ base: '2xl', lg: 'md' }}
    color={{ base: 'black', lg: 'white' }}
    marginRight="3rem"
    letterSpacing={{ base: '-0.2rem', lg: '-0.1rem' }}
    textTransform="capitalize"
  >
    {children}
  </Link>
);

const LetterHighlight = ({ children }) => (
  <Text
    as="span"
    fontSize={{ base: 'lg', sm: 'xl' }}
    fontWeight={700}
    color="#00A3FF"
  >
    {children}
  </Text>
);

const NavBar = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const btnRef = useRef(null);

  return (
    <>
      <Flex
        className="NavBar"
        height="100%"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        paddingRight="1rem"
      >
        <Link
          as={i18NextLink}
          to="/"
          fontSize={{ base: 'md', sm: 'lg' }}
          letterSpacing="-0.1rem"
          fontWeight={300}
          color="white"
          display="flex"
          flexDirection="row"
          alignItems="center"
          _hover={{ textDecoration: 'none' }}
        >
          <Image
            src={logoSrc}
            alt="CreditCalculator Icon"
            boxSize="70"
            top="0"
            right="100%"
            objectFit="contain"
            marginRight="1.2rem"
          />
          <Box>
            <LetterHighlight>C</LetterHighlight>
            redit
            <LetterHighlight>C</LetterHighlight>
            alculator
            <LetterHighlight> App</LetterHighlight>
          </Box>
        </Link>
        <Flex
          height="100%"
          flexDirection="row"
          alignItems="center"
          display={{ base: 'none', lg: 'flex' }}
        >
          <NavLink to="/">{t('home')}</NavLink>
          <NavLink to="/privacy-policy">{t('privacy-policy')}</NavLink>
          <NavLink to="/data-deletion">{t('data-deletion')}</NavLink>
          <Menu placement="bottom-end">
            <MenuButton
              as={Button}
              textTransform="uppercase"
              rightIcon={<ChevronDownIcon />}
              variant="ghost"
              color="white"
              fontSize="sm"
              colorScheme="whiteAlpha"
            >
              {t('language-menu.current')}
            </MenuButton>
            <MenuList>
              {LANGUAGES.map(lan => (
                <MenuItem
                  key={lan}
                  as={Link}
                  href={`/${lan}`}
                  fontSize="md"
                  paddingLeft="3rem"
                  paddingRight="10rem"
                  _hover={{
                    backgroundColor: 'blue.50',
                    textDecoration: 'none',
                  }}
                  display="flex"
                  alignItems="center"
                >
                  {t(`language-menu.${lan}`)}
                  {t('language-menu.current') === lan && (
                    <CheckIcon marginLeft="2rem" w="2rem" color="blue.400" />
                  )}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Flex>
        <Button
          ref={btnRef}
          variant="link"
          onClick={() => setIsOpen(true)}
          paddingY="1.5rem"
          paddingRight="1rem"
          paddingLeft="1rem"
          display={{ base: 'flex', lg: 'none' }}
        >
          <Text
            as="span"
            sx={{
              position: 'relative',
              width: '1.8rem',
              height: '0.1rem',
              backgroundColor: 'white',
            }}
            _before={{
              content: `''`,
              position: 'absolute',
              top: '-0.5rem',
              left: 0,
              width: '1.8rem',
              height: '0.1rem',
              backgroundColor: 'white',
            }}
            _after={{
              content: `''`,
              position: 'absolute',
              top: '0.5rem',
              left: 0,
              width: '1.8rem',
              height: '0.1rem',
              backgroundColor: 'white',
            }}
          />
        </Button>
      </Flex>

      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={() => setIsOpen(false)}
        finalFocusRef={btnRef}
        size="full"
      >
        <Box display={{ base: 'flex', lg: 'none' }}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader />
            <DrawerBody>
              <Flex flexDirection="column" justifyContent="center">
                <Text
                  fontSize="md"
                  letterSpacing="-0.1rem"
                  fontWeight="bold"
                  textTransform="uppercase"
                  color="gray.600"
                >
                  {t('pages')}
                </Text>
                <NavLink to="/">{t('home')}</NavLink>
                <NavLink to="/privacy-policy">{t('privacy-policy')}</NavLink>
                <NavLink to="/data-deletion">{t('data-deletion')}</NavLink>
                <Divider marginY="3rem" />
                <Text
                  fontSize="md"
                  letterSpacing="-0.1rem"
                  fontWeight="bold"
                  textTransform="uppercase"
                  color="gray.600"
                >
                  {t('languages')}
                </Text>
                {LANGUAGES.map(lan => (
                  <Link
                    key={lan}
                    href={`/${lan}`}
                    fontSize="2xl"
                    color="black"
                    marginRight="3rem"
                    letterSpacing="-0.2rem"
                    textTransform="capitalize"
                    display="flex"
                    alignItems="center"
                  >
                    {t(`language-menu.${lan}`)}
                    {t('language-menu.current') === lan && (
                      <CheckIcon marginLeft="2rem" w="2rem" color="blue.400" />
                    )}
                  </Link>
                ))}
              </Flex>
            </DrawerBody>
          </DrawerContent>
        </Box>
      </Drawer>
    </>
  );
};

export default NavBar;
